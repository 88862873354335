// src/pages/CookiePolicyPage.js
import React from 'react';
import NotFoundPage from '../notFoundPage';
import { connect } from "react-redux";

const WebsiteGeneral = ({ activeLang }) => {
    // Static content for the cookie policy
    const content = {
        tr: {
            title: "6698 SAYILI KİŞİSEL VERİLERİN KORUNMASI KANUNU KAPSAMINDA AYDINLATMA METNİ",
            description: `
                <h1 style="font-size:26px">6698 Sayılı Kişisel Verilerin Korunması Kanunu Kapsamında Aydınlatma Metni</h1>
                <p>ÜNLÜ TEKSTİL SANAYİ VE TİCARET A.Ş (“Ünlü Tekstil ya da Şirket”) yürütmekte olduğu faaliyetler nedeniyle kişisel verilerinizin işlenmesinde 6698 sayılı Kişisel Verilerin Korunması Kanunu (“KVKK”)’na uygun olarak her türlü teknik ve hukuki tedbiri almaktadır. İlgili kişiler, kişisel verilerinin işlenmesi, üçüncü kişilere aktarılması, kişisel veri toplamanın hukuki sebepleri ile KVKK’da yer alan hakları konularında detaylı bilgiye aşağıdaki aydınlatma metninden ulaşabilirler. Aynı zamanda KVKK Kurumu Sitesinde Şirketimizin VERBİS Envanteri mevcut olup bu konulardaki ayrıntılı kayıtlara ulaşmak mümkündür.</p>
                
                <h2 style="font-size:20px">I. Kişisel Verilerin İşlenme Amaçları</h2>
                <p>Kişisel verileriniz aşağıdaki durum ve koşullarda veri sorumlusu ya da atayacağı gerçek/tüzel kişiler tarafından işlenebilmektedir:</p>
                <ul>
                    <li>İlgili kanunlar uyarınca düzenlenme yükümlülüğümüzün bulunduğu evrakların düzenlenebilmesi amacıyla,</li>
                    <li>Ürün ve hizmetlerimizi iyileştirmek, geliştirmek, çeşitlendirmek ve ticari ilişki içerisinde olduğumuz gerçek/tüzel kişilere alternatifler sunabilmek amacıyla,</li>
                    <li>Hizmet standartlarımızı yükseltmek, geliştirmek, yeni teknoloji ve uygulamaların değerlendirmeleri ile ticari ve iş stratejilerimizin belirlenmesi ve uygulanması amacıyla,</li>
                    <li>Çeşitli raporların, araştırmaların ve sunumların hazırlanması, acil durum yönetim süreçlerinin planlanması, finans ve muhasebe işlerinin takibi, Şirketimiz nezdinde güvenliğin temin edilmesi amacıyla,</li>
                    <li>Çalışan adaylarımızın işe alım süreçlerinin değerlendirilmesi, çalışanlarımızın özlük dosyalarının oluşturulması ve Şirketimizin İnsan Kaynakları politikalarının sürdürülmesi amacıyla,</li>
                    <li>Şirketimizin kuruluş amacıyla bağlantılı olarak faaliyet alanlarımız kapsamında; tüm müşterilerimize uygun fiyat ve yüksek kalite anlayışıyla hizmet sunabilmek amacıyla,</li>
                    <li>Çalışanlarımızın performans düzeyinin ve çalışan memnuniyetinin arttırılması, sağlık durumlarının işe uygunluk açısından kontrolü, ücretlerinin ödenmesi, şikâyetlerinin değerlendirilmesi, eğitim ve kariyer planlarının belirlenmesi veya iş sağlığı ve güvenliğinin sağlanması amacıyla,</li>
                    <li>İş veya staj başvuruda bulunan adayların değerlendirilmesi, işe alım için internet üzerinden yapılan başvuruların değerlendirilmesi, işe alınanların işe başlatma işlemlerinin yapılması amacıyla,</li>
                    <li>Çalışanların kanuni izinlerinin düzenlenmesi, işten ayrılma işlemlerinin yapılması, işe devamlılıklarının tespiti amacıyla,</li>
                    <li>Güvenlik ve sair nedenlerle çalışma alanlarımızda, işyerleri ve ofislerimizde, binalarımızda, işletme sahalarımızda, araçlarımızda kamera görüntülerini kaydetmek ve bu yerlere giriş yapan ziyaretçilerin güvenliğini sağlamak amacıyla,</li>
                    <li>Ses ve arama kayıtları çağrı merkezlerimizle ya da şirket telefonlarımızla iletişim sağlanması halinde, iletişimin tespiti ve içeriğin belirlenebilmesi amacıyla,</li>
                    <li>İşletmemizi ziyaret edenler, şirketimiz tarafından düzenlenen toplantı, seminer ve diğer sosyal organizasyonlar, ödül törenleri, kurumsal toplantılarda çekilen fotoğraflar ve videolar; şirketimizin ve etkinliğin tanıtımı, duyurulması ve kamuoyunun bilgilendirilmesi amacıyla.</li>
                    <li>Mevzuattan kaynaklı yükümlülüklerimiz kapsamında iş sağlığı ve güvenliği için gerekli önlemlerin alınması, eğitim süreçlerinin planlanması ve yürütülmesi, sağlık ile ilgili hususlarda erken müdahalenin yapılabilmesi amacıyla,</li>
                    <li>Finansal raporlama ve risk yönetimi işlemlerinin icrası ve takibi, ziyaretçi kayıtlarının oluşturulması ve takibi, halkla ilişkiler ve pazarlama politikalarının geliştirilmesi ve iyileştirilmesi amacıyla.</li>
                    <li>Yargı, belediyeler ve ilgili diğer kamu kurum ve kuruluşlarının taleplerinin yerine getirilmesi amacıyla.</li>
                    <li>İş yürütme planlarının hazırlanması, vardiya listelerinin oluşturulması ve uygulanması amacıyla.</li>
                    <li>Servis hizmetlerinin ve çeşitli lojistik faaliyetlerinin planlanması ve icrası amacıyla.</li>
                </ul>
    
                <h2 style="font-size:20px">II. Kişisel Verilerin Aktarılması</h2>
                <p>İşlenen kişisel verileriniz yukarıdaki amaçlarla ve KVKK’nın belirlediği koşullar çerçevesinde:</p>
                <ul>
                    <li>İlgili mevzuat hükümlerine istinaden yükümlülüklerimizin yerine getirilmesi ve ticari faaliyetlerimizin denetimi amacıyla ilgili denetim firmalarına, özel entegratör firmalarına, bağımsız denetim firmalarına, gümrük firmalarına, mali müşavir/muhasebe firmalarına, hukuk bürolarına,</li>
                    <li>İlgili kamu kurum ve kuruluşlarının talepleri doğrultusunda ve talep amaçlarıyla sınırlı olarak hukuken yetkili kamu kurum ve kuruluşlarına,</li>
                    <li>Acil tıbbi müdahaleler ve iş sağlığı ve güvenliği yükümlülüklerini yerine getirmek amacıyla, iş sağlığı ve güvenliği şirketlerine, hastanelere ve sağlık kuruluşlarına,</li>
                    <li>Bankacılık ve finans işlemlerinin gerçekleştirilmesi amacıyla banka ve finans kurumlarına,</li>
                    <li>Reklam ve tanıtım süreçlerinin gerçekleştirilmesi amacıyla iş ortaklarımıza ve tedarikçilerimize,</li>
                    <li>Tamir, bakım, onarım ve servis hizmetleri yürüten çözüm ortağı şirketlere, tedarikçilerimize ve bu alanda faaliyet gösteren firmalara,</li>
                    <li>İşyeri güvenliğinin sağlanması amacıyla güvenlik şirketlerine,</li>
                    <li>İlgili kamu kurum ve kuruluşlarının talepleri doğrultusunda ve talep amaçlarıyla sınırlı olarak hukuken yetkili kamu kurum ve kuruluşlarına ve yargı organlarına aktarabilmekteyiz.</li>
                </ul>
    
                <h2 style="font-size:20px">III. Kişisel Veri Toplamanın Yöntemi ve Hukuki Sebebi</h2>
                <p>Kişisel verileriniz, Şirket ya da Şirket adına veri işleyen gerçek/tüzel kişiler tarafından, başvuru formları, internet sitesi, muhtelif sözleşmeler, her türlü bilgi formları, anketler, iş başvuru formları, iş sözleşmeleri, sosyal medya uygulamaları, müşterilerimizden, tedarikçilerimizden ve iş ortaklarımızdan gelen yazılı, sözlü ve dijital bildirimler, çağrı merkezleri ve burada sayılanlarla sınırlı olmamak üzere sözlü, yazılı veya elektronik kanallar aracılığı ile açık rızanız ya da KVKK’da öngörülen diğer veri işleme şartları kapsamında toplanmaktadır.</p>
                <p>Bu bilgiler, ticari ve idari faaliyetlerimizin, yasalar çerçevesinde sunulabilmesi ve bu kapsamda Şirketin hizmetlerini yürütebilmesi, ticari hayatını sürdürebilmesi ve yasalardan doğan mesuliyetlerini eksiksiz ve doğru bir şekilde yerine getirebilmesi amaçlarıyla edinilir.</p>
                <h1 style="font-size:26px">Kişisel Verilerin Saklanması, İmha Edilmesi ve Anonimleştirilmesi</h1>
            <p>Şirketimiz, kişisel verileri, kişisel verilerin işlenmesi amacı doğrultusunda, işleme amacıyla orantılı bir şekilde saklamaktadır. Kişisel veriler, işlenme amacının ve/veya sebebinin ortadan kalkmış olması durumunda ilgili mevzuatın gerektirdiği süre sonuna kadar saklanmaktadır. Kişisel verilerin işlenme amacı ve sebebi ortadan kalktığında yasalardan kaynaklanan yükümlülüklerimizin temini için gerekli zamanaşımı süreleri tamamlandığında kişisel veriler imha edilecek veya anonim hale getirilecektir.</p>
            
            <h2 style="font-size:20px">IV. İlgili Kişinin Hakları</h2>
            <p>Şirketimiz bünyesinde kişisel verisi işlenen gerçek kişiler KVKK’nın 11. maddesi uyarınca aşağıdaki haklara sahiptirler:</p>
            <ul>
                <li>Kişisel verisinin işlenip işlenmediğini öğrenme,</li>
                <li>Kişisel verileri işlenmişse buna ilişkin bilgi talep etme,</li>
                <li>Kişisel verilerin işlenme amacını ve bunların amacına uygun kullanılıp kullanılmadığını öğrenme,</li>
                <li>Yurt içinde veya yurt dışında kişisel verilerin aktarıldığı üçüncü kişileri bilme,</li>
                <li>Kişisel verilerin eksik veya yanlış işlenmiş olması hâlinde bunların düzeltilmesini isteme ve bu kapsamda yapılan işlemin kişisel verilerin aktarıldığı üçüncü kişilere bildirilmesini isteme,</li>
                <li>KVKK ve ilgili diğer kanun hükümlerine uygun olarak işlenmiş olmasına rağmen, işlenmesini gerektiren sebeplerin ortadan kalkması hâlinde kişisel verilerin silinmesini veya yok edilmesini isteme ve bu kapsamda yapılan işlemin kişisel verilerin aktarıldığı üçüncü kişilere bildirilmesini isteme,</li>
                <li>İşlenen verilerin münhasıran otomatik sistemler vasıtasıyla analiz edilmesi suretiyle kişinin kendisi aleyhine bir sonucun ortaya çıkmasına itiraz etme,</li>
                <li>Kişisel verilerin kanuna aykırı olarak işlenmesi sebebiyle zarara uğraması hâlinde zararın giderilmesini talep etme.</li>
            </ul>
            <p>Kişisel Verilerin Korunması Kanunu’nun 11. maddesinde sayılan yukarıda belirtilen haklarınıza ilişkin taleplerinizi, Veri Sorumlusuna Başvuru Usul ve Esasları Hakkında Tebliğ’e uygun olarak Şirketimize iletebilirsiniz. Hızlı ve etkili biçimde sonuçlandırılabilmesini teminen taleplerinizi, Kişisel Verilerin Korunması Kanunu Veri Sorumlusuna Başvuru Formu’nu doldurarak yapmanızı rica ederiz. İlgili kişinin talepleri en kısa sürede ve nihayetinde en geç otuz (30) gün içerisinde ücretsiz olarak değerlendirip karara bağlanacaktır. Değerlendirme ve karar verme işleminin ayrıca bir maliyeti gerektirmesi durumunda Kişisel Verileri Koruma Kurulu tarafından belirlenen tarifedeki ücret esas alınacaktır.</p>
            <p>Kamuoyuna saygılarımızla arz olunur.</p>
            <p>ÜNLÜ TEKSTİL SANAYİ VE TİCARET A.Ş</p>
            `
        },
        en: {
            title: "INFORMATION TEXT UNDER THE LAW NO. 6698 ON THE PROTECTION OF PERSONAL DATA",
            description: `
                <h1 style="font-size:26px">Information Text Under the Law No. 6698 on the Protection of Personal Data</h1>
                <p>ÜNLÜ TEKSTİL SANAYİ VE TİCARET A.Ş. ("Ünlü Tekstil or Company") takes all technical and legal measures in accordance with the Law No. 6698 on the Protection of Personal Data ("KVKK") in processing your personal data due to its activities. Relevant persons can access detailed information about the processing of their personal data, the transfer to third parties, the legal reasons for the collection of personal data, and their rights as stated in the KVKK through the following information text. Additionally, the Company's VERBİS Inventory is available on the KVKK Institution's website, providing access to detailed records on these matters.</p>
                
                <h2 style="font-size:20px">I. Purposes of Processing Personal Data</h2>
                <p>Your personal data may be processed by the data controller or assigned real/legal persons under the following circumstances:</p>
                <ul>
                    <li>To arrange documents for which we are obliged to regulate under relevant laws,</li>
                    <li>To improve, develop, diversify our products and services, and provide alternatives to real/legal persons with whom we have commercial relationships,</li>
                    <li>To enhance our service standards, develop new technologies and applications, and determine and implement our commercial and business strategies,</li>
                    <li>To prepare various reports, research, and presentations, plan emergency management processes, follow up on finance and accounting tasks, and ensure security within our Company,</li>
                    <li>To evaluate the recruitment processes of our job candidates, create personnel files for our employees, and sustain our Human Resources policies,</li>
                    <li>In connection with the purpose of the Company’s establishment; to provide services to all our customers with a focus on competitive pricing and high quality,</li>
                    <li>To improve the performance level and satisfaction of our employees, check health conditions for job suitability, pay salaries, evaluate complaints, determine training and career plans, or ensure occupational health and safety,</li>
                    <li>To evaluate candidates applying for jobs or internships, assess online applications, and carry out onboarding processes for the hired individuals,</li>
                    <li>To arrange legal leaves for employees, carry out resignation processes, and determine continuity at work,</li>
                    <li>To record camera footage for security and other reasons in our workplaces, offices, buildings, operational areas, and vehicles, and ensure the safety of visitors entering these places,</li>
                    <li>To evaluate the suitability of employees and job candidates concerning our obligation to employ ex-offenders as required,</li>
                    <li>To monitor and identify communications through voice and call recordings when contacting our call centers or company phones,</li>
                    <li>To promote and inform the public about our Company and events through photos and videos taken at meetings, seminars, and other social organizations organized by our Company.</li>
                    <li>To take necessary measures for occupational health and safety as required by legislation, plan and implement training processes, and enable early intervention in health-related matters,</li>
                    <li>To execute and monitor financial reporting and risk management, create and track visitor records, and develop and improve public relations and marketing policies.</li>
                    <li>To fulfill requests from courts, municipalities, and other relevant public institutions and organizations.</li>
                    <li>To prepare work execution plans, create and implement shift lists.</li>
                    <li>To plan and execute service and various logistical activities.</li>
                </ul>
    
                <h2 style="font-size:20px">II. Transfer of Personal Data</h2>
                <p>Your processed personal data may be transferred for the purposes mentioned above and within the framework of the conditions set forth by KVKK:</p>
                <ul>
                    <li>To relevant auditing firms, special integrators, independent auditing firms, customs firms, financial advisors/accounting firms, and law offices in order to fulfill our obligations under relevant legislation and for the audit of our commercial activities,</li>
                    <li>To legally authorized public institutions and organizations limited to the requests of relevant public institutions and organizations,</li>
                    <li>To occupational health and safety companies, hospitals, and healthcare institutions in order to fulfill urgent medical interventions and occupational health and safety obligations,</li>
                    <li>To banks and financial institutions for the execution of banking and financial transactions,</li>
                    <li>To our business partners and suppliers for the execution of advertising and promotional processes,</li>
                    <li>To solution partner companies handling repair, maintenance, and service services, suppliers, and companies operating in this field,</li>
                    <li>To security companies to ensure workplace safety,</li>
                    <li>To legally authorized public institutions and organizations and judicial bodies as required by the requests of relevant public institutions and organizations.</li>
                </ul>
    
                <h2 style="font-size:20px">III. Method of Collecting Personal Data and Legal Reasons</h2>
                <p>Your personal data is collected by our Company or assigned real/legal persons through application forms, the internet site, various contracts, all kinds of information forms, surveys, job application forms, employment contracts, social media applications, written, verbal, and digital notifications from our customers, suppliers, and business partners, call centers, and not limited to these, via verbal, written, or electronic channels with your explicit consent or within the framework of other data processing conditions stipulated in the KVKK.</p>
                <p>This information is obtained to ensure that our commercial and administrative activities can be carried out within the framework of laws, allowing the Company to provide its services, sustain its commercial life, and fulfill its legal obligations accurately and completely.</p>
                  <h1 style="font-size:26px">Storage, Destruction, and Anonymization of Personal Data</h1>
            <p>Our Company stores personal data proportionate to the purpose of processing. Personal data is retained until the period required by relevant legislation expires if the purpose and/or reason for processing has been eliminated. When the purpose and reason for processing personal data cease to exist, personal data will be destroyed or anonymized after the necessary statute of limitations has expired to fulfill our legal obligations.</p>
            
            <h2 style="font-size:20px">V. Rights of the Relevant Person</h2>
            <p>Individuals whose personal data is processed within our Company have the following rights under Article 11 of the KVKK:</p>
            <ul>
                <li>To learn whether their personal data is being processed,</li>
                <li>If their personal data has been processed, to request information regarding it,</li>
                <li>To learn the purpose of processing personal data and whether it is used in accordance with its purpose,</li>
                <li>To know the third parties to whom personal data has been transferred, domestically or internationally,</li>
                <li>To request the correction of personal data if it is incomplete or incorrectly processed and to request that this correction be communicated to third parties to whom the personal data has been transferred,</li>
                <li>To request the deletion or destruction of personal data if the reasons requiring processing have ceased, even if it has been processed in accordance with the KVKK and relevant other laws, and to request that this process be communicated to third parties to whom the personal data has been transferred,</li>
                <li>To object to the occurrence of a result against the person by means of exclusive automatic processing of the data,</li>
                <li>To request compensation for damages in case of suffering damage due to the unlawful processing of personal data.</li>
            </ul>
            <p>You can submit your requests regarding the rights listed above to our Company in accordance with the Regulation on the Principles and Procedures for Applications to the Data Controller. To ensure prompt and effective resolution of your requests, we kindly ask you to fill out the KVKK Data Controller Application Form. Requests from the relevant person will be evaluated and concluded free of charge as soon as possible, and at the latest within thirty (30) days. In cases where the evaluation and decision-making process incurs an additional cost, the fee determined by the Personal Data Protection Board will apply.</p>
            <p>Respectfully presented to the public.</p>
            <p>ÜNLÜ TEKSTİL SANAYİ VE TİCARET A.Ş</p>
            `
        }
    };
    
    // Aktif dile göre içerik seçimi
  

    const pageContent = content[activeLang];

    const styles = {
        cookiePolicyBlock: {
            maxWidth: '90%',
            margin: '100px auto',
            padding: '20px',
            backgroundColor: '#f9f9f9',
            borderRadius: '8px',
            boxShadow: '0 2px 10px rgba(0, 0, 0, 0.1)',
            fontFamily: 'Montserrat-Regular',
        },
        title: {
            fontSize: '26px', // Updated to 26px
            fontWeight: 400,  // Set font weight to 400
            color: '#333',
            marginBottom: '15px',
            textAlign: 'center',
            fontFamily: 'Montserrat-Regular',

        },
        subtitle: {
            fontSize: '20px', // Set to 20px
            fontWeight: 400,  // Set font weight to 400
            color: '#444',
            marginTop: '20px',
            marginBottom: '10px',
            fontFamily: 'Montserrat-Regular',

        },
        list: {
            listStyleType: 'disc',
            paddingLeft: '20px',
            fontWeight: 400,  // Set font weight to 400
            fontFamily: 'Montserrat-Regular',

        },
        listItem: {
            marginBottom: '10px',
            lineHeight: '1.6',
            fontWeight: 400,  // Set font weight to 400
            fontFamily: 'Montserrat-Regular',

        },
        textContent: {
            fontSize: '20px', // Set to 20px
            fontWeight: 400,  // Set font weight to 400
            color: '#555',
            lineHeight: '1.6',
            fontFamily: 'Montserrat-Regular',

        },
        table: {
            width: '100%',
            borderCollapse: 'collapse',
            marginTop: '20px',
            fontSize: '20px', // Set to 20px
            overflowX: 'auto',
            fontFamily: 'Montserrat-Regular',

        },
        th: {
            backgroundColor: '#eee',
            padding: '10px',
            textAlign: 'left',
            fontWeight: 400,  // Set font weight to 400
            fontFamily: 'Montserrat-Regular',
            
        },
        td: {
            padding: '10px',
            border: '1px solid #ddd',
            fontWeight: 400,  // Set font weight to 400
            fontFamily: 'Montserrat-Regular',

        },
    };

    return (
        <div>
            {
                pageContent ?
                <div style={styles.cookiePolicyBlock} >
                    <h3 style={styles.title}>{pageContent.title}</h3>

                    <div className='text-item' style={styles.textContent} dangerouslySetInnerHTML={{ __html: pageContent.description }} />
                </div>
                    
                :
                <NotFoundPage {...this.props} />
            }
        </div>
    );
};

const mapStateToProps = (state) => ({
    activeLang: state.langReducer.activeLang,
});

export default connect(mapStateToProps)(WebsiteGeneral);