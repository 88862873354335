// src/pages/CookiePolicyPage.js
import React from 'react';
import NotFoundPage from '../notFoundPage';
import { connect } from "react-redux";

const CookiePolicyPageSub = ({ activeLang }) => {
    // Static content for the cookie policy
    const content = {
        tr: {
            title: "Çerez Aydınlatma Metni",
            text: `
                ÜNLÜ TEKSTİL SANAYİ VE TİCARET ANONİM ŞİRKETİ (“ÜnlüTekstil” veya “Şirket”) olarak “https://unlutekstil.com.tr/tr” internet sitesinden (“İnternet Sitesi”) en verimli şekilde faydalanabilmeniz ve ziyaretçi deneyiminizi geliştirebilmek amacıyla çerez (“cookie”) kullanıyoruz. Veri sorumlusu sıfatıyla 6698 Sayılı Kişisel Verilerin Korunması Kanunu (“Kanun” veya “KVKK”) ve ilgili mevzuat kapsamında aydınlatma yükümlülüğümüzü yerine getirmek amacıyla işbu metin ile sitemizde kullandığımız çerezler hakkında sizi bilgilendirmeyi hedeflemekteyiz.
                <h2 style="font-size:20px">Çerez nedir?</h4>
                Çerezler, bilgisayarınızda veya mobil cihazınızda ziyaret ettiğiniz internet sitesi tarafından tarayıcınıza gönderilen metin parçalarıdır. Ziyaretinizle ilgili bilgilerin; mesela, tercih ettiğiniz dilin veya uğradığınız sayfaların hatırlanmasına yardımcı olan çerezler aynı zamanda size özel bir deneyim sunmak, internet sitesi üzerinden sunulan hizmetleri geliştirmek ve dolayısıyla deneyiminizi iyileştirmek için kullanılır.
                <h2 style="font-size:20px">Çerez Türleri</h4>
                İnternet sitelerinde kullanılan çerezler ziyaretçinin kullanım amacına yönelik Zorunlu/Teknik, Analitik/Performans, Reklam/Pazarlama ve İşlevsel/Fonksiyonel çerezlerdir.
                <h2 style="font-size:20px">Ziyaretçinin Kullanım Amacına Göre Çerezler</h4>
                <ul>
                    <p>Zorunlu/Teknik Çerezler:</strong> Ziyaretçinin bir sayfanın güvenli alanlarına erişimi gibi sitenin özel bölümlerinde gezinebilmesini ve bunları kullanabilmesini sağlamak için zorunlu/teknik çerezler kullanılmaktadır.</p>
                    <p>Analitik/Performans Çerezleri:</strong> İnternet sitelerinde kullanıcıların davranışlarını analiz etmek amacıyla istatistiki ölçümüne imkân veren çerezlerdir.</p>
                    <p>Reklam/Pazarlama Çerezleri:</strong> Ziyaretçinin bir sayfanın güvenli alanlarına erişimi gibi sitenin özel bölümlerinde gezinebilmesini ve bunları kullanabilmesini sağlamak için zorunlu/teknik çerezler kullanılmaktadır.</p>
                    <p>İşlevsel/Fonksiyonel Çerezler:</strong> İnternet sitesinde kullanılan kişiselleştirme ve tercihlerin hatırlanması amaçları ile kullanılan çerezlerdir.</p>
                </ul>
            `,
            cookieList: [
                { provider: "unlutekstil.com.tr", name: "_ga", purpose: "Ziyaretçinin gerçekliğini doğrulamak, oturum açma verilerinin hileli kullanımını önlemek ve ziyaretçi verilerini yetkisiz erişime karşı korumak için güvenlik çerezi.", type: "Analitik/Performans", duration: "399 gün" },
                { provider: "unlutekstil.com.tr", name: "_ga_#", purpose: "İnternet sayfasının nasıl erişildiği, kullanıldığı veya çalıştığını analiz edip size daha iyi bir kullanıcı deneyimi sunmak için kullanılır.", type: "Analitik/Performans", duration: "399 gün" },
                { provider: "unlutekstil.com.tr", name: "__Secure-3PSIDCC", purpose: "Kullanıcı tercihlerini tutmak için kullanılır.", type: "Reklam ve pazarlama çerezleri", duration: "360 gün" },
                { provider: "unlutekstil.com.tr", name: "__Secure-1PAPISID", purpose: "Güvenlik amaçlı çalışan cookie'dir.", type: "Reklam ve pazarlama çerezleri", duration: "365 gün" },
                { provider: "unlutekstil.com.tr", name: "HSID", purpose: "Ziyaretçinin gerçekliğini doğrulamak, oturum açma verilerinin hileli kullanımını önlemek ve kullanıcı verilerini yetkisiz erişime karşı korumak için güvenlik çerezi.", type: "Reklam ve pazarlama çerezleri", duration: "24 gün" },
                { provider: "unlutekstil.com.tr", name: "__Secure-3PAPISID", purpose: "Yeniden hedefleme yoluyla alakalı ve kişiselleştirilmiş reklamlar göstermek için web sitesi ziyaretçilerinin ilgi alanlarını gösteren bir profil oluşturur.", type: "Reklam ve pazarlama çerezleri", duration: "365 gün" },
                { provider: "unlutekstil.com.tr", name: "__Secure-3PSID", purpose: "Kullanıcı profillendirilmesi ve reklam hedeflemesi için kullanılır.", type: "Reklam ve pazarlama çerezleri", duration: "365 gün" },
                { provider: "unlutekstil.com.tr", name: "SID", purpose: "Güvenlik için kullanılan çerezler, kullanıcıların kimliğini doğrular, dolandırıcılığı önler ve bir hizmetle etkileşime girerken kullanıcıları korur.", type: "Reklam ve pazarlama çerezleri", duration: "365 gün" },
                { provider: "unlutekstil.com.tr", name: "__Secure-1PSID", purpose: "Güvenlik amaçlı çalışan cookie'dir.", type: "Reklam ve pazarlama çerezleri", duration: "365 gün" },
                { provider: "unlutekstil.com.tr", name: "__Secure-1PSIDCC", purpose: "Güvenlik amaçlı çalışan cookie'dir.", type: "Reklam ve pazarlama çerezleri", duration: "Oturum süresi" },
                { provider: "unlutekstil.com.tr", name: "SIDCC", purpose: "Google/Youtube güvenlik çerezi.", type: "Reklam ve pazarlama çerezleri", duration: "360 gün" },
                { provider: "unlutekstil.com.tr", name: "NID", purpose: "Ziyaretçilerin tercihlerini saklar ve son aramalara ve etkileşimlere dayalı olarak Google web sitelerindeki reklamları kişiselleştirir.", type: "Reklam ve pazarlama çerezleri", duration: "185 gün" },
                { provider: "unlutekstil.com.tr", name: "AEC", purpose: "'AEC' çerezleri, internette gezinme oturumundaki isteklerin başka siteler değil, kullanıcı tarafından yapılmasını sağlar.", type: "Reklam ve pazarlama çerezleri", duration: "120 gün" },
                { provider: "unlutekstil.com.tr", name: "SEARCH_SAMESITE", purpose: "Tanımlama bilginizin birinci taraf bağlamıyla mı yoksa aynı site bağlamıyla mı sınırlandırılacağını bildirmenize olanak tanır.", type: "Reklam ve pazarlama çerezleri", duration: "185 gün" },
                { provider: "unlutekstil.com.tr", name: "__Secure-ENID", purpose: "Yeniden pazarlama için gereklidir ve reklamları ve ücretsiz ürün listelemelerini sunmak için kullanılır.", type: "Reklam ve pazarlama çerezleri", duration: "330 gün" },
                { provider: "unlutekstil.com.tr", name: "SAPISID", purpose: "Google, YouTube tarafından barındırılan videolar için ziyaretçi bilgilerini toplar.", type: "Reklam ve pazarlama çerezleri", duration: "365 gün" },
                { provider: "unlutekstil.com.tr", name: "APISID", purpose: "Son aramalara ve etkileşimlere dayalı olarak web sitelerindeki Google reklamlarını kişiselleştirir.", type: "Reklam ve pazarlama çerezleri", duration: "365 gün" },
                { provider: "unlutekstil.com.tr", name: "SSID", purpose: "Google, Google Haritalar ile entegre haritalarda YouTube tarafından barındırılan videolar için ziyaretçi bilgilerini toplar.", type: "Reklam ve pazarlama çerezleri", duration: "365 gün" },
                { provider: "unlutekstil.com.tr", name: "1P_JAR", purpose: "Son aramalara ve önceki etkileşimlere dayalı olarak, Google sitelerinde özel reklamlar gösterilir.", type: "Reklam ve pazarlama çerezleri", duration: "Oturum süresi" },
                { provider: "unlutekstil.com.tr", name: "OGPC", purpose: "Bu tanımlama bilgileri, Google tarafından, Google eşleştirilmiş sayfaları görüntülerken kullanıcı tercihlerini ve bilgilerini depolamak için kullanılır.", type: "Reklam ve pazarlama çerezleri", duration: "Oturum süresi" },
                { provider: "unlutekstil.com.tr", name: "DV", purpose: "Reklamları daha optimize hale getirmeyi sağlamak için kullanılır.", type: "Reklam ve pazarlama çerezleri", duration: "Oturum süresi" },
                { provider: "unlutekstil.com.tr", name: "OGP", purpose: "Bu web sitesi, IP adresiniz de dahil olmak üzere Google tarafından Google.com web sitesinin şartları altında iletilen, işlenen ve saklanan bilgileri toplayan bir web analiz hizmeti olan Google Analytics'i kullanır.", type: "Reklam ve pazarlama çerezleri", duration: "Oturum süresi" },
                { provider: "unlutekstil.com.tr", name: "_GRECAPTCHA", purpose: "Google ReCaptcha, kullanıcının web sitenizdeki davranışını izlemek için çerezleri kullanır.", type: "Zorunlu/Teknik", duration: "90 gün" },
                { provider: "unlutekstil.com.tr", name: "_ga_XJ5PTMW4FX", purpose: "Sayfa görüntülemelerini depolamak ve saymak için.", type: "Reklam ve pazarlama çerezleri", duration: "30 gün" },
                { provider: "unlutekstil.com.tr", name: "cfmrk_cic", purpose: "CloudFlare kullanan sitelerle ilişkili bir tanımlama bilgisi. Güvenilir web trafiğini belirlemek için kullanılır.", type: "İşlevsellik çerezleri", duration: "30 gün" },
                { provider: "unlutekstil.com.tr", name: "permittedCookies", purpose: "Bu çerezler İnternet Sitesinin size sunulması, internet sitesinin ve kişisel verilerinizin güvenliği, dolandırıcılık faaliyetlerinin, verilerin çalınması teşebbüslerinin ve diğer şüpheli işlemlerin önlenmesi gibi internet sitesinde de yer alan özelliklerin kullanılabilmesi için zorunludur.", type: "Zorunlu/Teknik", duration: "Oturum süresi" },
                { provider: "unlutekstil.com.tr", name: "_ga_724JRY2ZKQ", purpose: "Sayfa görüntülemelerini depolamak ve saymak için.", type: "Reklam ve pazarlama çerezleri", duration: "30 gün" },
                { provider: "unlutekstil.com.tr", name: "_ga_PHVG60J2FD", purpose: "Sayfa görüntülemelerini depolamak ve saymak için.", type: "Reklam ve pazarlama çerezleri", duration: "Oturum süresi" },
                { provider: "unlutekstil.com.tr", name: "_fbp", purpose: "Web sitelerindeki ziyaretleri depolamak ve izlemek için.", type: "Reklam ve pazarlama çerezleri", duration: "365 gün" },
                { provider: "unlutekstil.com.tr", name: "_gaexp", purpose: "Deneyimlerin ve oturumların kimliklerini saklamak için.", type: "Performans ve analitik çerezleri", duration: "30 gün" },
                { provider: "unlutekstil.com.tr", name: "_gcl_au", purpose: "Dönüşümleri depolamak ve izlemek için.", type: "Reklam ve pazarlama çerezleri", duration: "30 gün" },
                { provider: "unlutekstil.com.tr", name: "_mkto_trk", purpose: "Etkileşimi saklamak ve izlemek için.", type: "İşlevsellik çerezleri", duration: "365 gün" },
                { provider: "unlutekstil.com.tr", name: "_rdt_uuid", purpose: "Reddirt tarafından ilgi alanlarınıza yönelik bir profil oluşturmaya ve size alakalı reklamlar göstermeye yardımcı olmak için ayarlanır.", type: "Reklam ve pazarlama çerezleri", duration: "365 gün" },
                { provider: "unlutekstil.com.tr", name: "_mkto_trk", purpose: "CloudFlare kullanan sitelerle ilişkili bir tanımlama bilgisi. Güvenilir web trafiğini belirlemek için kullanılır.", type: "İşlevsellik çerezleri", duration: "30 gün" },
            ],
            personalDataProcessing: `
            <h2 style="font-size:20px">Kişisel Verilerin İşlenme Amaçları ve Hukuki Sebepler</h4>
            <p>Zorunlu/ Teknik çerezler aracılığıyla toplanan kişisel verileriniz, Kanunun 5’inci maddesinin (2) numaralı fıkrasının (f) bendi “İlgili kişinin temel hak ve özgürlüklerine zarar vermemek kaydıyla, veri sorumlusunun meşru menfaatleri için veri işlenmesinin zorunlu olması” kapsamında işlenmektedir.</p>
            <p>Analitik/Performans çerezleri aracılığıyla toplanan kişisel verileriniz, Kanun’un 5’inci maddesinin (1) numaralı fıkrası kapsamında açık rızanızın alınması suretiyle işlenmektedir.</p>
            <p>İşlevsel/Fonksiyonel çerezler aracılığıyla toplanan kişisel verileriniz, Kanun’un 5’inci maddesinin (1) numaralı fıkrası kapsamında açık rızanızın alınması suretiyle işlenmektedir.</p>
            <p>Reklam/Pazarlama çerezleri aracılığıyla toplanan kişisel verileriniz, Kanun’un 5’inci maddesinin (1) numaralı fıkrası kapsamında açık rızanızın alınması suretiyle işlenmektedir.</p>
            <p>Kullandığımız çerezler ziyaretçileri daha iyi anlamamızı sağlayarak hizmet performansını arttırır.</p>
            <ul>
                <li>Ziyaret ettiğiniz sitenin çalışmasını ve gerekli hizmetleri sunmasını sağlar.</li>
                <li>Sitenin işleyiş biçimini iyileştirerek performans arttırmak amacıyla kullanılır.</li>
                <li>Ziyaretçinin site içinde yaptığı seçimleri kaydederek site ziyaretçilerine kullanım kolaylığı sağlar.</li>
                <li>İnternet sitesinin ziyaretçiler tarafından nasıl kullanıldığını anlamamızı sağlayarak, istatistiki verileri bir araya getirmemize ve genel eğilimi anlayarak bu veriler doğrultusunda içerik geliştirmemize yardımcı olur.</li>
            </ul>
            <p>Kullandığımız çerezler bilgisayarınıza zarar vermez ve virüs içermez. Hiçbir koşulda kimliğinizi tanımlayabileceğimiz verileri toplamaz, saklamaz ve işlememizi sağlamaz.</p>
        `,
        cookieManagement: `
            <h2 style="font-size:20px">Çerez Tercihleri Nasıl Yönetilir?</h4>
            <p>Çerezlerin kullanımına ilişkin tercihlerinizi değiştirmek ya da bazı çerezleri engellemek veya silmek için tarayıcınızın ayarlarını değiştirmeniz yeterlidir. Birçok tarayıcı çerezleri kontrol edebilmeniz için size çerezleri kabul etme veya reddetme, yalnızca belirli türdeki çerezleri kabul etme ya da bir internet sitesinin cihazınıza çerez depolamayı talep ettiğinde tarayıcı tarafından uyarılma seçeneği sunar.</p>
            <p>Ayrıca, daha önce tarayıcınıza kaydedilmiş çerezlerin silinmesi de mümkündür. Ancak çerezleri devre dışı bırakır veya reddederseniz, bazı tercihleri manuel olarak ayarlamanız gerekebilir.</p>
            <ul>
                <li><a href="http://www.google.com/support/chrome/bin/answer.py?hl=en&answer=95647">Google Chrome</a></li>
                <li><a href="https://support.microsoft.com/en-us/help/17442/windows-internet-explorerdelete-manage-cookies">Internet Explorer</a></li>
                <li><a href="http://support.mozilla.com/en-US/kb/Cookies">Mozilla Firefox</a></li>
                <li><a href="https://support.apple.com/kb/ph19214">Opera</a></li>
                <li><a href="https://support.microsoft.com/tr-tr/help/17442/windows-internet-explorerdelete-manage-cookies">Microsoft Edge</a></li>
            </ul>
        `,
        rights: `
            <h2 style="font-size:20px">Kişisel Verileriniz ile İlgili Haklarınız</h4>
            <ul>
                <li>Kişisel veri işlenip işlenmediğini öğrenme.</li>
                <li>Kişisel verileri işlenmişse buna ilişkin bilgi talep etme.</li>
                <li>Kişisel verilerin işlenme amacını ve bunların amacına uygun kullanılıp kullanılmadığını öğrenme.</li>
                <li>Yurt içinde veya yurt dışında kişisel verilerin aktarıldığı üçüncü kişileri bilme.</li>
                <li>Kişisel verilerin eksik veya yanlış işlenmiş olması hâlinde bunların düzeltilmesini isteme.</li>
                <li>Kanun’un 7. maddesinde öngörülen şartlar çerçevesinde kişisel verilerin silinmesini veya yok edilmesini isteme.</li>
                <li>(d) ve (e) bentleri uyarınca yapılan işlemlerin, kişisel verilerin aktarıldığı üçüncü kişilere bildirilmesini isteme.</li>
                <li>İşlenen verilerin münhasıran otomatik sistemler vasıtasıyla analiz edilmesi suretiyle kişinin kendisi aleyhine bir sonucun ortaya çıkmasına itiraz etme.</li>
                <li>Kişisel verilerin kanuna aykırı olarak işlenmesi sebebiyle zarara uğraması hâlinde zararın giderilmesini talep etme.</li>
            </ul>
            <p>6698 sayılı Kanun’un ilgili kişinin haklarını düzenleyen 11’inci maddesi kapsamındaki taleplerinizi, Veri Sorumlusuna Başvuru Usul ve Esasları Hakkında Tebliğ uyarınca Veri Sorumlusuna Başvuru Formu ile tarafımıza iletebilirsiniz.</p>
        `,
    },
        en: {
            title: "Cookie Policy",
            text: `
                ÜNLÜ TEKSTİL SANAYİ VE TİCARET ANONİM ŞİRKETİ (“ÜnlüTekstil” or “Company”) uses cookies on the website “https://unlutekstil.com.tr/tr” (“Website”) to enable you to benefit from it in the most efficient way and to improve your visitor experience. As the data controller, we aim to inform you about the cookies we use on our site with this text in accordance with the obligation to clarify under the Law on the Protection of Personal Data No. 6698 (“Law” or “KVKK”) and relevant legislation.
                <h2 style="font-size:20px">What are Cookies?</h4>
                Cookies are pieces of text sent to your browser by the website you visit that are stored on your computer or mobile device. Cookies help remember information about your visit, such as your preferred language or the pages you visited, and are used to provide a personalized experience, improve the services offered through the website, and enhance your experience.
                <h2 style="font-size:20px">Types of Cookies</h4>
                Cookies used on websites are compulsory/technical, analytical/performance, advertising/marketing, and functional cookies based on the visitor's usage purpose.
                <h2 style="font-size:20px">Cookies Based on Visitor's Purpose</h4>
                <ul>
                    <p>Compulsory/Technical Cookies:</strong> These cookies are used to enable the visitor to navigate the special sections of the site and to use them.</p>
                    <p>Analytical/Performance Cookies:</strong> These cookies enable statistical measurement to analyze user behavior on websites.</p>
                    <p>Advertising/Marketing Cookies:</strong> These cookies are used to enable the visitor to navigate the special sections of the site.</p>
                    <p>Functional Cookies:</strong> These cookies are used for personalization and remembering preferences.</p>
                </ul>
            `,
            cookieList: [
                { provider: "unlutekstil.com.tr", name: "_ga", purpose: "Used to validate the visitor's authenticity, prevent fraudulent use of login data, and protect visitor data from unauthorized access.", type: "Analytical/Performance", duration: "399 days" },
                { provider: "unlutekstil.com.tr", name: "_ga_#", purpose: "Used to analyze how the website is accessed, used, or functioning to provide a better user experience.", type: "Analytical/Performance", duration: "399 days" },
                { provider: "unlutekstil.com.tr", name: "__Secure-3PSIDCC", purpose: "Used to retain user preferences.", type: "Advertising and marketing cookies", duration: "360 days" },
                { provider: "unlutekstil.com.tr", name: "__Secure-1PAPISID", purpose: "Security-related cookie.", type: "Advertising and marketing cookies", duration: "365 days" },
                { provider: "unlutekstil.com.tr", name: "HSID", purpose: "Used to validate the visitor's authenticity, prevent fraudulent use of login data, and protect user data from unauthorized access.", type: "Advertising and marketing cookies", duration: "24 days" },
                { provider: "unlutekstil.com.tr", name: "__Secure-3PAPISID", purpose: "Creates a profile indicating the interests of website visitors to show related and personalized ads through retargeting.", type: "Advertising and marketing cookies", duration: "365 days" },
                { provider: "unlutekstil.com.tr", name: "__Secure-3PSID", purpose: "Used for user profiling and ad targeting.", type: "Advertising and marketing cookies", duration: "365 days" },
                { provider: "unlutekstil.com.tr", name: "SID", purpose: "Cookies used for security, verifying user identities, preventing fraud, and protecting users during interactions with a service.", type: "Advertising and marketing cookies", duration: "365 days" },
                { provider: "unlutekstil.com.tr", name: "__Secure-1PSID", purpose: "Security-related cookie.", type: "Advertising and marketing cookies", duration: "365 days" },
                { provider: "unlutekstil.com.tr", name: "__Secure-1PSIDCC", purpose: "Security-related cookie.", type: "Advertising and marketing cookies", duration: "Session duration" },
                { provider: "unlutekstil.com.tr", name: "SIDCC", purpose: "Google/YouTube security cookie.", type: "Advertising and marketing cookies", duration: "360 days" },
                { provider: "unlutekstil.com.tr", name: "NID", purpose: "Stores visitor preferences and personalizes ads on Google websites based on recent searches and interactions.", type: "Advertising and marketing cookies", duration: "185 days" },
                { provider: "unlutekstil.com.tr", name: "AEC", purpose: "Ensures that requests made during browsing sessions are made by the user and not by other sites, preventing malicious sites from acting on behalf of the user.", type: "Advertising and marketing cookies", duration: "120 days" },
                { provider: "unlutekstil.com.tr", name: "SEARCH_SAMESITE", purpose: "Allows you to specify whether your identification information should be restricted to first-party or same-site contexts.", type: "Advertising and marketing cookies", duration: "185 days" },
                { provider: "unlutekstil.com.tr", name: "__Secure-ENID", purpose: "Necessary for remarketing and to serve ads and free product listings.", type: "Advertising and marketing cookies", duration: "330 days" },
                { provider: "unlutekstil.com.tr", name: "SAPISID", purpose: "Collects visitor information for videos hosted by Google and YouTube.", type: "Advertising and marketing cookies", duration: "365 days" },
                { provider: "unlutekstil.com.tr", name: "APISID", purpose: "Personalizes ads on Google websites based on recent searches and interactions.", type: "Advertising and marketing cookies", duration: "365 days" },
                { provider: "unlutekstil.com.tr", name: "SSID", purpose: "Collects visitor information for videos hosted by YouTube in maps integrated with Google Maps.", type: "Advertising and marketing cookies", duration: "365 days" },
                { provider: "unlutekstil.com.tr", name: "1P_JAR", purpose: "Shows personalized ads on Google sites based on recent searches and previous interactions.", type: "Advertising and marketing cookies", duration: "Session duration" },
                { provider: "unlutekstil.com.tr", name: "OGPC", purpose: "Used by Google to store user preferences and information when viewing Google-matched pages.", type: "Advertising and marketing cookies", duration: "Session duration" },
                { provider: "unlutekstil.com.tr", name: "DV", purpose: "Used to optimize ads.", type: "Advertising and marketing cookies", duration: "Session duration" },
                { provider: "unlutekstil.com.tr", name: "OGP", purpose: "This website uses Google Analytics, a web analysis service provided by Google, which collects information including your IP address under the terms of Google.com.", type: "Advertising and marketing cookies", duration: "Session duration" },
                { provider: "unlutekstil.com.tr", name: "_GRECAPTCHA", purpose: "Google ReCaptcha uses cookies to track user behavior on your website.", type: "Necessary/Technical", duration: "90 days" },
                { provider: "unlutekstil.com.tr", name: "_ga_XJ5PTMW4FX", purpose: "Used to store and count page views.", type: "Advertising and marketing cookies", duration: "30 days" },
                { provider: "unlutekstil.com.tr", name: "cfmrk_cic", purpose: "A cookie associated with sites using CloudFlare. Used to identify trustworthy web traffic.", type: "Functional cookies", duration: "30 days" },
                { provider: "unlutekstil.com.tr", name: "permittedCookies", purpose: "These cookies are necessary for the presentation of the website, the security of the website and your personal data, and for preventing fraudulent activities, attempts to steal data, and other suspicious actions.", type: "Necessary/Technical", duration: "Session duration" },
                { provider: "unlutekstil.com.tr", name: "_ga_724JRY2ZKQ", purpose: "Used to store and count page views.", type: "Advertising and marketing cookies", duration: "30 days" },
                { provider: "unlutekstil.com.tr", name: "_ga_PHVG60J2FD", purpose: "Used to store and count page views.", type: "Advertising and marketing cookies", duration: "Session duration" },
                { provider: "unlutekstil.com.tr", name: "_fbp", purpose: "Used to store and track visits to websites.", type: "Advertising and marketing cookies", duration: "365 days" },
                { provider: "unlutekstil.com.tr", name: "_gaexp", purpose: "Used to store identities of experiences and sessions.", type: "Performance and analytical cookies", duration: "30 days" },
                { provider: "unlutekstil.com.tr", name: "_gcl_au", purpose: "Used to store and track conversions.", type: "Advertising and marketing cookies", duration: "30 days" },
                { provider: "unlutekstil.com.tr", name: "_mkto_trk", purpose: "Used to store and track interactions.", type: "Functional cookies", duration: "365 days" },
                { provider: "unlutekstil.com.tr", name: "_rdt_uuid", purpose: "Set by Reddirt to create a profile based on your interests and to show you relevant ads.", type: "Advertising and marketing cookies", duration: "365 days" },
                { provider: "unlutekstil.com.tr", name: "_mkto_trk", purpose: "A cookie associated with sites using CloudFlare. Used to identify trustworthy web traffic.", type: "Functional cookies", duration: "30 days" },
            ],
            
            personalDataProcessing: `
            <h2 style="font-size:20px">Purposes and Legal Grounds for Processing Personal Data</h4>
            <p>Personal data collected through necessary/technical cookies is processed under Article 5(2)(f) of the Law, which states that processing is necessary for the legitimate interests of the data controller, provided that it does not harm the fundamental rights and freedoms of the data subject.</p>
            <p>Personal data collected through analytical/performance cookies is processed with your explicit consent as per Article 5(1) of the Law.</p>
            <p>Personal data collected through functional cookies is processed with your explicit consent as per Article 5(1) of the Law.</p>
            <p>Personal data collected through advertising/marketing cookies is processed with your explicit consent as per Article 5(1) of the Law.</p>
            <p>The cookies we use help us understand visitors better and improve service performance.</p>
            <ul>
                <li>Ensures the website functions and provides necessary services.</li>
                <li>Used to improve the functioning of the site for better performance.</li>
                <li>Records the choices made by the visitor on the site for ease of use.</li>
                <li>Helps us understand how the website is used by visitors, allowing us to compile statistical data and develop content based on general trends.</li>
            </ul>
            <p>The cookies we use do not harm your computer and do not contain viruses. They do not collect, store, or process data that can identify you under any circumstances.</p>
        `,
        cookieManagement: `
            <h2 style="font-size:20px">How to Manage Cookie Preferences?</h4>
            <p>You can change your preferences regarding the use of cookies or block or delete certain cookies by changing your browser settings. Many browsers offer options to accept or reject cookies, accept only certain types of cookies, or be alerted when a website requests to store cookies on your device.</p>
            <p>It is also possible to delete cookies previously saved in your browser. However, if you disable or reject cookies, you may need to manually adjust some preferences as we may not recognize or associate your account(s) and some features and services on the website may not function properly.</p>
            <ul>
                <li><a href="http://www.google.com/support/chrome/bin/answer.py?hl=en&answer=95647">Google Chrome</a></li>
                <li><a href="https://support.microsoft.com/en-us/help/17442/windows-internet-explorerdelete-manage-cookies">Internet Explorer</a></li>
                <li><a href="http://support.mozilla.com/en-US/kb/Cookies">Mozilla Firefox</a></li>
                <li><a href="https://support.apple.com/kb/ph19214">Opera</a></li>
                <li><a href="https://support.microsoft.com/en-us/help/17442/windows-internet-explorerdelete-manage-cookies">Microsoft Edge</a></li>
            </ul>
        `,
        rights: `
            <h2 style="font-size:20px">Your Rights Regarding Personal Data</h4>
            <ul>
                <li>Learn whether personal data is being processed.</li>
                <li>Request information if personal data has been processed.</li>
                <li>Learn the purpose of processing personal data and whether they are used appropriately for that purpose.</li>
                <li>Be informed about third parties to whom personal data is transferred, either domestically or internationally.</li>
                <li>Request correction of personal data if it is incomplete or inaccurate.</li>
                <li>Request deletion or destruction of personal data under the conditions specified in Article 7 of the Law.</li>
                <li>Request notification of operations performed under (d) and (e) to third parties to whom personal data has been transferred.</li>
                <li>Object to the emergence of a result against the person by exclusively analyzing the processed data through automated systems.</li>
                <li>Request compensation for damages due to unlawful processing of personal data.</li>
            </ul>
            <p>Requests regarding the rights of the relevant person regulated in Article 11 of Law No. 6698 can be submitted to us through the Data Controller Application Form as per the Communiqué on the Procedure and Principles of Application to the Data Controller.</p>
        `,
    },
};

    const pageContent = content[activeLang];

    const styles = {
        cookiePolicyBlock: {
            maxWidth: '90%',
            margin: '100px auto',
            padding: '20px',
            backgroundColor: '#f9f9f9',
            borderRadius: '8px',
            boxShadow: '0 2px 10px rgba(0, 0, 0, 0.1)',
            fontFamily: 'Montserrat-Regular',
        },
        title: {
            fontSize: '26px', // Updated to 26px
            fontWeight: 400,  // Set font weight to 400
            color: '#333',
            marginBottom: '15px',
            textAlign: 'center',
            fontFamily: 'Montserrat-Regular',

        },
        subtitle: {
            fontSize: '20px', // Set to 20px
            fontWeight: 400,  // Set font weight to 400
            color: '#444',
            marginTop: '20px',
            marginBottom: '10px',
            fontFamily: 'Montserrat-Regular',

        },
        list: {
            listStyleType: 'disc',
            paddingLeft: '20px',
            fontWeight: 400,  // Set font weight to 400
            fontFamily: 'Montserrat-Regular',

        },
        listItem: {
            marginBottom: '10px',
            lineHeight: '1.6',
            fontWeight: 400,  // Set font weight to 400
            fontFamily: 'Montserrat-Regular',

        },
        textContent: {
            fontSize: '20px', // Set to 20px
            fontWeight: 400,  // Set font weight to 400
            color: '#555',
            lineHeight: '1.6',
            fontFamily: 'Montserrat-Regular',

        },
        table: {
            width: '100%',
            borderCollapse: 'collapse',
            marginTop: '20px',
            fontSize: '20px', // Set to 20px
            overflowX: 'auto',
            fontFamily: 'Montserrat-Regular',

        },
        th: {
            backgroundColor: '#eee',
            padding: '10px',
            textAlign: 'left',
            fontWeight: 400,  // Set font weight to 400
            fontFamily: 'Montserrat-Regular',
            
        },
        td: {
            padding: '10px',
            border: '1px solid #ddd',
            fontWeight: 400,  // Set font weight to 400
            fontFamily: 'Montserrat-Regular',

        },
    };

    return (
        <div>
            {
                pageContent ?
                <div style={styles.cookiePolicyBlock} >
                    <h3 style={styles.title}>{pageContent.title}</h3>

                    <div className='text-item' style={styles.textContent} dangerouslySetInnerHTML={{ __html: pageContent.text }} />
                    <div style={{ overflowX: 'auto' }}> {/* Taşmayı önlemek için konteyner */}
        <table style={styles.table}>
            <thead>
                <tr>
                    <th style={styles.th}>Çerez Hizmet Sağlayıcı</th>
                    <th style={styles.th}>Çerez İsmi</th>
                    <th style={styles.th}>Çerez Amacı</th>
                    <th style={styles.th}>Çerez Tipi</th>
                    <th style={styles.th}>Oturum süresi</th>
                </tr>
            </thead>
            <tbody>
                {pageContent.cookieList.map((cookie, index) => (
                    <tr key={index}>
                        <td style={styles.td}>{cookie.provider}</td>
                        <td style={styles.td}>{cookie.name}</td>
                        <td style={styles.td}>{cookie.purpose}</td>
                        <td style={styles.td}>{cookie.type}</td>
                        <td style={styles.td}>{cookie.duration}</td>
                    </tr>
                ))}
            </tbody>
        </table>
    </div>
                    {/* Kişisel Verilerin İşlenme Amaçları ve Hukuki Sebepler */}
                    <div style={styles.textContent}  dangerouslySetInnerHTML={{ __html: pageContent.personalDataProcessing }} />
                    
                    {/* Çerez Yönetimi */}
                    <div style={styles.textContent}  dangerouslySetInnerHTML={{ __html: pageContent.cookieManagement }} />
                    
                    {/* Kullanıcı Hakları */}
                    <div style={styles.textContent}  dangerouslySetInnerHTML={{ __html: pageContent.rights }} />
                    </div>
                    
                    
                :
                <NotFoundPage {...this.props} />
            }
        </div>
    );
};

const mapStateToProps = (state) => ({
    activeLang: state.langReducer.activeLang,
});

export default connect(mapStateToProps)(CookiePolicyPageSub);